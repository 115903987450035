new WOW({
    boxClass: 'wow', // default
    animateClass: 'animated', // default
    offset: 0, // default
    mobile: true, // default
    live: true // default
}).init();


// slideshow vars:
var ssRunning = false,
    ssOnce = false,
    ssDelay = 4500 /*ms*/ ,
    ssButtonClass = '.pswp__button--playpause';

var lightBox = null;


$(function() {


    //Fastclick
    // FastClick.attach(document.body);

    setupAnimations();

    initPhotoswipe();

    setupScrollToTop();

});



function setupAnimations() {
    var itemsToAnimate = $('.animateme');

    $('.post-cover-image').imagesLoaded({ background: true }, function() {

        $(".post-header").addClass("loaded");
        $('.post-meta .animateme').addClass('animated');



    });


}

function setupScrollToTop() {
    var offset = 250;
    var duration = 300;

    $(window).scroll(function() {
        if ($(this).scrollTop() > offset) {
            $('.scrolltotop').fadeIn(duration);
        } else {
            $('.scrolltotop').fadeOut(duration);
        }
    });

    $('.scrolltotop').click(function(event) {
        event.preventDefault();
        $('html, body').animate({ scrollTop: 0 }, duration);
        return false;
    });

}



function initPhotoswipe() {
    var initPhotoSwipeFromDOM = function(gallerySelector) {
        // parse slide data (url, title, size ...) from DOM elements
        // (children of gallerySelector)
        var parseThumbnailElements = function(el) {
            var thumbElements = el.getElementsByClassName("photo"),
                numNodes = thumbElements.length,
                items = [],
                figureEl,
                linkEl,
                size,
                item;

            for (var i = 0; i < numNodes; i++) {
                figureEl = thumbElements[i]; // <figure> element

                // include only element nodes
                if (figureEl.nodeType !== 1) {
                    continue;
                }

                linkEl = figureEl.children[0]; // <a> element

                size = [];

                size[0] = linkEl.getAttribute("data-img-width");
                size[1] = linkEl.getAttribute("data-img-height");

                // create slide object
                item = {
                    src: linkEl.getAttribute("href"),
                    w: parseInt(size[0], 10),
                    h: parseInt(size[1], 10)
                };

                if (figureEl.children.length > 1) {
                    // <figcaption> content
                    item.title = figureEl.children[1].innerHTML;
                }

                if (linkEl.children.length > 0) {
                    // <img> thumbnail element, retrieving thumbnail url
                    item.msrc = linkEl.children[0].getAttribute("data-src");
                }

                item.el = figureEl; // save link to element for getThumbBoundsFn
                items.push(item);
            }

            return items;
        };

        // find nearest parent element
        var closest = function closest(el, fn) {
            return el && (fn(el) ? el : closest(el.parentNode, fn));
        };

        // triggers when user clicks on thumbnail
        var onThumbnailsClick = function(e) {
            e = e || window.event;
            e.preventDefault ? e.preventDefault() : (e.returnValue = false);

            var eTarget = e.target || e.srcElement;

            // find root element of slide
            var clickedListItem = closest(eTarget, function(el) {
                return el.tagName && el.tagName.toUpperCase() === "FIGURE";
            });

            if (!clickedListItem) {
                return;
            }

            // find index of clicked item by looping through all child nodes
            // alternatively, you may define index via data- attribute
            var clickedGallery = document.querySelectorAll(".story")[0],
                childNodes = Array.prototype.slice.call(document.querySelectorAll(".photo")),
                numChildNodes = childNodes.length,
                nodeIndex = 0,
                index;

            for (var i = 0; i < numChildNodes; i++) {
                if (childNodes[i].nodeType !== 1) {
                    continue;
                }

                if (childNodes[i] === clickedListItem) {
                    index = nodeIndex;
                    break;
                }
                nodeIndex++;
            }

            if (index >= 0) {
                // open PhotoSwipe if valid index found
                openPhotoSwipe(index, clickedGallery);
            }
            return false;
        };

        // parse picture index and gallery index from URL (#&pid=1&gid=2)
        var photoswipeParseHash = function() {
            var hash = window.location.hash.substring(1),
                params = {};

            if (hash.length < 5) {
                return params;
            }

            var vars = hash.split("&");
            for (var i = 0; i < vars.length; i++) {
                if (!vars[i]) {
                    continue;
                }
                var pair = vars[i].split("=");
                if (pair.length < 2) {
                    continue;
                }
                params[pair[0]] = pair[1];
            }

            if (params.gid) {
                params.gid = parseInt(params.gid, 10);
            }

            if (!params.hasOwnProperty("pid")) {
                return params;
            }
            params.pid = parseInt(params.pid, 10);
            return params;
        };

        var openPhotoSwipe = function(index, galleryElement, disableAnimation) {
            var pswpElement = document.querySelectorAll(".pswp")[0],
                gallery,
                options,
                items;

            items = parseThumbnailElements(galleryElement);

            // define options (if needed)
            options = {
                index: index,
                shareEl: true,

                shareButtons: [{
                        id: "facebook",
                        label: "Auf Facebook teilen",
                        url: "https://www.facebook.com/sharer/sharer.php?u={{url}}"
                    },
                    {
                        id: "download",
                        label: "Bild herunterladen",
                        url: "{{raw_image_url}}",
                        download: true
                    }
                ],

                // define gallery index (for URL)
                galleryUID: galleryElement.getAttribute("data-pswp-uid"),

                getThumbBoundsFn: function(index) {
                    // See Options -> getThumbBoundsFn section of documentation for more info
                    var thumbnail = items[index].el.getElementsByTagName("img")[0], // find thumbnail
                        pageYScroll =
                        window.pageYOffset || document.documentElement.scrollTop,
                        rect = thumbnail.getBoundingClientRect();

                    return {
                        x: rect.left,
                        y: rect.top + pageYScroll,
                        w: rect.width
                    };
                }
            };

            if (disableAnimation) {
                options.showAnimationDuration = 0;
            }

            // Pass data to PhotoSwipe and initialize it
            gallery = new PhotoSwipe(
                pswpElement,
                PhotoSwipeUI_Default,
                items,
                options
            );
            lightBox = gallery;

            setSlideshowState(ssButtonClass, false /* not running from the start */ );

            // start timer for the next slide in slideshow after prior image has loaded
            lightBox.listen("afterChange", function() {
                if (ssRunning && ssOnce) {
                    ssOnce = false;
                    setTimeout(gotoNextSlide, ssDelay);
                }
            });
            lightBox.listen("destroy", function() {
                lightBox = null;
            });

            gallery.init();
        };

        // loop through all gallery elements and bind events
        var galleryElements = document.querySelectorAll(gallerySelector + " figure");

        for (var i = 0, l = galleryElements.length; i < l; i++) {
            galleryElements[i].setAttribute("data-pswp-uid", i + 1);
            galleryElements[i].onclick = onThumbnailsClick;
        }

        // Parse URL and open gallery if it contains #&pid=3&gid=1
        var hashData = photoswipeParseHash();
        if (hashData.pid > 0 && hashData.gid > 0) {
            openPhotoSwipe(hashData.pid - 1, galleryElements[hashData.gid - 1], true);
        }
    };

    // execute above function
    initPhotoSwipeFromDOM(".story");

    /* slideshow management */
    $(ssButtonClass).on("click", function(e) {
        // toggle slideshow on/off
        setSlideshowState(this, !ssRunning);
    });

    /* override handling of Esc key to stop slideshow on first esc (note Esc to leave fullscreen never gets here) */
    $(document).keydown(function(e) {
        if (e.altKey || e.ctrlKey || e.shiftKey || e.metaKey) return;
        if ((e.key === "Escape" || e.which == 27) /*esc*/ && !!lightBox) {
            if (e.preventDefault) e.preventDefault();
            else e.returnValue = false;
            if (ssRunning) {
                setSlideshowState(ssButtonClass, false);
            } else {
                lightBox.close();
            }
        }
    });

    $(".slideshowtrigger").on("click", function(e) {
        $(".photos .photo")
            .first()
            .click();
        $(ssButtonClass).click();
    });
}

function setSlideshowState(el, running) {
    if (running) {
        setTimeout(gotoNextSlide, ssDelay * 0.7 /* first time wait less */ );
    }
    var title = running ? "Pause Slideshow" : "Play Slideshow";
    $(el)
        .removeClass(running ? "play" : "pause") // change icons defined in css
        .addClass(running ? "pause" : "play")
        .prop("title", title);
    ssRunning = running;
}

function gotoNextSlide() {
    console.log("next called");
    if (ssRunning && !!lightBox) {
        ssOnce = true;
        lightBox.next();
        // start counter for next slide in 'afterChange' listener
    }
}